<template>
  <el-form
    ref="form"
    :model="form"
    @keyup.enter.native="submit"
  >
    <el-form-item
      prop="text"
      :rules="rules.required"
      :error="validateErrors.text"
    >
      <label class="main-label width-100">
        <span class="main-label-form__title">Сообщение</span>

        <el-input
          v-model="form.text"
          type="textarea"
          :rows="3"
          label="Сообщение"
          placeholder="Введите сообщение"
        />
      </label>
    </el-form-item>

    <div class="block-buttons block-buttons__right">
      <el-button
        type="warning"
        class="el-button-h-40"
        plain
        @click="closeModal(null)"
      >
        Закрыть
      </el-button>

      <el-button
        class="el-button-h-40"
        type="primary"
        plain
        @click="submit"
      >
        Отправить
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'info-modal',
  props: {
    propsData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isSubmit: false,
      form: {
        text: ''
      },
      validateErrors: {
        text: null
      },
      rules: {
        required: {
          required: true,
          message: 'Поле не должно быть пустым',
          trigger: 'submit'
        }
      }
    }
  },
  computed: {
    ...mapGetters('categories', { categories: 'getData' })
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('bugReport', ['sendBugReport']),
    submit () {
      this.isSubmit = true

      this.$refs.form.validate(async (valid, obj) => {
        if (!valid) {
          // Костыль. Необходим для корректного вывода ошибок
          // Из за того что поля в форме рендерятся динамически
          // появляется проблема с вывыодом ошибок
          Object.keys(obj).forEach((field) => {
            this.validateErrors[field] = obj[field]?.[0]?.message
          })

          return
        }

        await this.$bus.emit('start-loader')
        await this.sendBugReport(this.form.text)
        await this.$bus.emit('stop-loader')

        this.isSubmit = false
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
